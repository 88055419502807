<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="650px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                    <el-form-item label="要求文件">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false"
                            :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_filename"
                            :before-remove="remove_filename" :file-list="m.filenameFile">
                            <el-button size="mini" type="primary">点击上传</el-button>
                            <el-link style="margin-left:20px;" type="primary" v-if="m.filenameFile[0]" :href="m.filenameFile[0].url"
                                target="_blank">下载</el-link>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="描述">
                        <el-input type="textarea" rows="6" placeholder="描述" v-model="m.info"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                type: [],
                filename: [],
                projectId: [],
                info: [],
            },
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                data.filenameFile = JSON.parse(data.filename);
                this.m = data;
            } else {
                this.m = {
                    type: data.type,
                    filename: '',
                    projectId: data.projectId,
                    info: '',
                    filenameFile: []
                }
            }
            this.title = data.name;
        },
        success_filename(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.filenameFile) {
                this.m.filenameFile = [];
            } this.m.filenameFile.push(response.data);
            console.log(fileList);
        },
        remove_filename(file, fileList) {
            this.m.filenameFile = fileList;
        },

        //提交需求信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.m.filename = JSON.stringify(this.m.filenameFile.map(item => {
                        let a = {};
                        a.name = item.name;
                        a.url = item.url;
                        return a;
                    }));
                    this.sa.post("/story/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>