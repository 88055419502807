<style scoped>
</style>

<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="70%" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                    <el-form-item label="任务名称：" prop="name">
                        <el-input v-model="m.name"></el-input>
                    </el-form-item>

            
                    <el-form-item label="类型：" prop="type">
                        <input-enum enumName="TaskTgTypeEnum" v-model="m.type"></input-enum>
                    </el-form-item>
                   

                    <el-form-item label="指派给：" prop="userId">
                        <el-select v-model="m.userId" filterable>
                            <el-option value="">请选择</el-option>
                            <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-tag :key="tag" v-for="tag in userTags" closable :disable-transitions="false"
                            @close="handleClose(tag)" style="margin-left: 10px;">
                            {{tag}}
                        </el-tag>
                    </el-form-item>

                    <el-form-item label="当前状态：" style="width: 500px" :inline="true" prop="state">
                        <div class="inline">
                            <el-select v-model="m.state">
                                <el-option value="">请选择</el-option>
                                <el-option v-for="item in taskStateEnum" :key="item.code" :value="item.code"
                                    :label="item.name"></el-option>
                            </el-select>

                            <el-steps :space="500" style="margin-left:30px;" :active="parseInt(m.state)"
                                finish-status="success">
                                <el-step v-for="item in taskStateEnum" :key="item.code" :title="item.name"></el-step>

                            </el-steps>
                        </div>
                    </el-form-item>
                    <el-form-item label="预计完成时间：" prop="planDate">
                        <el-date-picker v-model="m.planDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="预计完成时间："></el-date-picker>

                        <span class="space"> 实际完成时间：</span>
                        <el-date-picker v-model="m.finishDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="实际完成时间"></el-date-picker>

                    </el-form-item>

                    <el-form-item label="需求和注意事项">

                        <quill-editor ref="text" v-model="m.content" class="myQuillEditor" :options="editorOption" />

                        
                    </el-form-item>

                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">提 交
                        </el-button>
                    </el-form-item>
                </el-form>

            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {},
            fileList: [],
            taskStateEnum: [],
            users: [],
            projects: [],
            userTags: [],
            editorOption:{
                action: '/api/file/upload',
                methods: 'post',
                name: 'file'
            }
        }
    },
    methods: {
        open: function (data) {
            this.init();
            this.isShow = true;
            if (data.id) {
                this.title = "修改 任务";
                this.m = data;
                this.userTags = this.m.users.split(',');
            } else {
                this.m = {
                    type: 1,
                    category: data.category,
                    name: "",
                    projectId: data.projectId,
                    implementId:data.implementId,
                    userId: '',
                    state: '',
                    planDate: "",
                    finishDate: "",
                    userIds: ''
                };
                this.title = "添加 任务";
            }
            console.log("我拿到数据=============", this.m);
        },


        parse(a) {
            return parseInt(a);
        },
        init() {
            this.sa.get("/getEnums?enumName=taskStateEnum").then(res => {
                this.taskStateEnum = res.data;
            })
            this.sa.get("/project/list").then(res => {
                this.projects = res.data;
            })

            this.sa.get("/user/all").then(res => {
                this.users = res.data
            });
        },

        handleClose(tag) {
            this.userTags.splice(this.userTags.indexOf(tag), 1);
        },

        success(response, file, fileList) {
            console.log(response, file, fileList);
        },

        //提交任务信息
        ok: function (formName) {
            this.projects.forEach(element => {
                if (element.id == this.m.projectId) {
                    this.m.projectCode = element.code;
                }
            });
            this.m.users = this.userTags.join(",");
            this.users.forEach(item => {
                if (item.id == this.m.userId) {
                    if (this.m.users) {
                        if (this.m.users.indexOf(item.name) == -1) {
                            this.m.users = this.m.users + "," + item.name;
                        }
                    } else {
                        this.m.users = item.name;
                    }
                }
            })
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sa.post("/task/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
    created() { },
};
</script>

<style >
.el-steps {
    min-width: 400px;
}
</style>

<style  scoped>
.inline {
    display: flex;
    flex-direction: row;
    width: 1000px;
    
}
.space {
    margin-left: 50px;
    margin-right: 20px;
    color: #606266;
}
</style>