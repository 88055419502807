<template>
    <div class="vue-box">
        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>项目实施</el-breadcrumb-item>
                <el-breadcrumb-item>{{implement.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="c-panel-l">
            <div class="banner">
                <div @click="detail(item)" v-for="item in muens" :key="item.name" class="banner-item">
                    <div><img :src="item.image"></div>
                    <div class="item-info">{{item.name}}</div>
                </div>
            </div>
        </div>

        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">

                <el-form-item label="任务名称：">
                    <el-input v-model="p.name" placeholder="模糊查询"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <input-enum enumName="TaskStateEnum" v-model="p.state"></input-enum>
                </el-form-item>
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5();">查询 </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
                <el-table-column label="id" prop="id"></el-table-column>
                <el-table-column label="研发类型" prop="typeTgEnum"></el-table-column>
                <el-table-column label="任务名称" prop="name"></el-table-column>
                <el-table-column label="指派用户" prop="users"></el-table-column>
                <el-table-column label="状态" prop="stateEnum"></el-table-column>
                <el-table-column label="计划完成时间" prop="planDate"></el-table-column>
                <el-table-column label="实际完成时间" prop="finishDate"></el-table-column>
                <el-table-column label="任务描述" min-width="120" :show-overflow-tooltip="true" prop="content"></el-table-column>
                <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">
                        <el-button class="c-btn" type="primary" icon="el-icon-edit" @click="update(s.row)">修改
                        </el-button>
                        <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>
        <!-- 增改组件 -->
        <add-op ref="new-or-update"></add-op>
        <add-or-update ref="add-or-update"></add-or-update>
    </div>

</template>

<script>
import addOp from "../task/new.vue";
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
import addOrUpdate from "../story/add.vue";
export default {
    data() {
        return {
            p: {
                pageSize: 10,
                page: 1,
                name: "",
                state: "",
                implementId: 0,
                projectId: 0
            },
            dataCount: 0,
            dataList: [],
            implement: { name: "" },
            muens: [
                {
                    name: "项目交付要求",
                    url: "",
                    image: require("@/static/img/jiaofu.png"),
                    id: 1
                },
                {
                    name: "项目实施计划",
                    url: "",
                    image: require("@/static/img/jihua.png"),
                    id: 5
                },
                {
                    name: "施工方案",
                    url: "",
                    image: require("@/static/img/shigong.png"),
                    id: 6
                },
                {
                    name: "预计工厂量与施工进度",
                    url: "taskSchedule-list",
                    image: require("@/static/img/yaoqiu.png"),
                    id: 6
                },
                {
                    name: "测试项目流程",
                    url: "taskTest-list",
                    image: require("@/static/img/gongchengliang.png"),
                    id: 7
                },
                {
                    name: "项目风险登记",
                    url: "taskRisk-list",
                    image: require("@/static/img/fengxian.png"),
                    id: 8
                }
            ],
            storys: [],
            project: {}
        };
    },
    components: {
        addOrUpdate,
        inputEnum,
        addOp
    },

    methods: {
        // 数据刷新
        f5: function() {
            this.p.projectId = this.project.id;
            this.p.implementId = this.implement.id;

            this.sa.put("/task/listPage", this.p).then(res => {
                this.dataList = res.data.content.map(item => {
                    return item;
                });
                this.dataCount = res.data.totalElements;
            });

            this.sa.get("/story/list/" + this.project.id).then(res => {
                this.storys = res.data.map(item => {
                    item.name = this.project.name;
                    return item;
                });
            });
        },
        // 删除
        del: function(data) {
            this.sa.confirm(
                "是否删除，此操作不可撤销",
                function() {
                    this.sa.delete("/task/delete/" + data.id).then(res => {
                        console.log(res);
                        this.sa.arrayDelete(this.dataList, data);
                        this.sa.ok(res.message);
                    });
                }.bind(this)
            );
        },

        //更新
        update(row) {
            row.projectId = this.project.id;
            row.implementId = this.implement.id;
            this.$refs["new-or-update"].open(row);
        },

        detail(row) {
            let item = this.storys.filter(t => {
                return t.type == row.id && t.projectId == this.project.id;
            })[0];
            if (!item) {
                item = { projectId: this.project.id, type: row.id };
            }
            if (row.url) {
                this.sa_admin.navigateTo(row.url, this.implement);
            } else {
                item.name = row.name;
                this.$refs["add-or-update"].open(item);
            }
        },

        //添加
        add: function() {
            var row = { id: 0 };
            row.projectId = this.project.id;
            row.implementId = this.implement.id;
            this.$refs["new-or-update"].open(row);
        }
    },

    created() {
        this.implement = this.sa_admin.params;
        this.project = this.implement.project;

        console.log("=======", this.project);
        this.f5();
    }
};
</script>

<style>
.banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    background-color: #ffffff;
}

.banner .banner-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 26.7%;
    height: 90px;
}
.banner .banner-item img {
    width: 42px;
}
</style>